<template>
    <div class="container__about">
        <b-overlay :show="loadDimension" rounded="sm">
        </b-overlay>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loadDimension: true
        }
    },
    methods:{
        reloadForce(){
                if(Object.keys(this.getClient()).length>0){
                  this.$router.push({name: 'Inicio'});
            }else{
                setTimeout(() => {
                    this.reloadForce()
                }, 3000);
            }
        }
    },
    beforeMount(){
         if(Object.keys(this.getClient()).length>0){
                  this.$router.push({name: 'Inicio'});
            }else{
                setTimeout(() => {
                    this.reloadForce()
                }, 3000);
            }
    }
}
</script>
<style scoped>
.container__about{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>